import { FC, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsContainer, LoadingButton, Modal, ThemeContext } from '@forma/forma-ui-kit';
import formatPrice from 'helpers/formatPrice';
import { ITariff, ITariffDuration, ITariffItem, ITariffPrices } from 'interfaces/tariffs.interface';
import { IPaymentSubscription } from 'interfaces/billing.interface';
import { deleteCookie } from 'helpers/cookies';
import { currencies } from 'data/mock';
import { DEFAULT_PAYMENT_METHOD } from 'data/constants';

import {
  ICreateInvoiceData,
  useCreateInvoiceMutation
} from 'store/billing/billingApi';
import { useGetTariffPricesQuery, useSetTariffMutation } from 'store/tariffs/tariffsApi';

import PaymentMethods from '../PaymentMethods';
import ModalSuccess from './ModalSuccess';
import ModalError from './ModalError';
import ModalCalc from './ModalCalc';
import ModalPay from './ModalPay';

import styles from './payment-modal.module.css';

interface PaymentModalProps {
  open: boolean,
  onClose: () => void,
  mode: 'view'|'change'|'await',
  subscription?: IPaymentSubscription,
  tariff: ITariff,
  selectedTariff: ITariffItem,
  selectedPattern: ITariffDuration,
  members: number,
  defaultMethod?: string
}

interface PaymentModalInnerProps {
  open: boolean,
  onClose: () => void,
  isLoading?: boolean,
  tariffId: string,
  tariffName: string,
  tariffPrices?: ITariffPrices,
  tariffDtTo: string,
  duration: number,
  subscription?: IPaymentSubscription,
  onBill: (data: {[key: string]: string}) => void,
  onPay: () => void,
  isPayLoading?: boolean,
  defaultMethod?: string
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

const PaymentModalInner: FC<PaymentModalInnerProps> = ({
  open, onClose, tariffName, tariffPrices, duration, subscription, onBill, onPay, isLoading, isPayLoading,
  defaultMethod = DEFAULT_PAYMENT_METHOD
}) => {
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);
  const currency = currencies.ru;

  const [ paymentMethod, setPaymentMethod ] = useState<string>(defaultMethod);
  const [ billingData, setBillingData ] = useState<{[key: string]: string}|null>(null);
  const [ isCalcOpen, setCalcOpen ] = useState<boolean>(false);

  const renewPrice = tariffPrices?.renewPrice ?? tariffPrices?.price ?? 0;
  const amountToPay = tariffPrices?.amountToPay ?? tariffPrices?.price ?? 0;

  return (
    <Modal
      width="100%"
      maxWidth="928px"
      open={open}
      onClose={onClose}
      title={t('subscription.payment_method')}
      closeOnDocumentClick={false}
    >
      <div className={styles.modal}>
        <div className={styles.methods}>
          <PaymentMethods
            selected={paymentMethod}
            onSelect={setPaymentMethod}
            onFill={setBillingData}
            subscription={subscription}
          />
        </div>
        <ButtonsContainer className={styles.buttons}>
          {!!(paymentMethod !== 'transfer' && renewPrice) && (
            <div className={styles.notice}>
              <Trans
                i18nKey="subscription.payment_subscription_notice"
                values={{
                  total: formatPrice(renewPrice, currency),
                  period: duration === 360 ? t('count_years', { count: 1 }) : t('count_months', { count: duration/30 })
                }}
                components={{
                  // eslint-disable-next-line
                  faq_link: <a className='accent-text' href={`${landingUrl}/${lang}/#faq`} target="_blank" />
                }}
              />
            </div>
          )}
          {isLoading ? (
            <div className={styles.total}>
              <div className={styles.totalPrice}>
                <span className={styles.totalLabel}>{t('subscription.total')}:</span>
                <span className={classNames(styles.totalValue, 'skeleton-loader')}></span>
              </div>
            </div>
          ) : (
            <div className={styles.total}>
              <div className={styles.totalPrice}>
                <span className={styles.totalLabel}>{t('subscription.total')}:</span>
                <span className={styles.totalValue}>{formatPrice(amountToPay, currency)}</span>
              </div>
              <span className={styles.totalNoticeLink} onClick={() => setCalcOpen(true)}>
                {t('subscription.how_we_this_calc')}
              </span>
              {tariffPrices && (
                <ModalCalc
                  open={isCalcOpen}
                  onClose={() => setCalcOpen(false)}
                  tariffName={tariffName}
                  tariffPrices={tariffPrices}
                />
              )}
            </div>
          )}
          {paymentMethod === 'transfer' ? (
            <LoadingButton
              className={classNames(styles.paymentButton, 'button-bill')}
              viewStyle="primary"
              onClick={() => billingData && onBill(billingData)}
              disabled={!amountToPay || !tariffPrices?.canChoose || !billingData}
              isLoading={isLoading || isPayLoading}
            >
              {t('subscription.bill')}
            </LoadingButton>
          ) : (
            <LoadingButton
              className={classNames(styles.paymentButton, 'button-pay')}
              viewStyle="primary"
              onClick={onPay}
              disabled={!amountToPay || !tariffPrices?.canChoose}
              isLoading={isLoading || isPayLoading}
            >
              {t('subscription.pay')}
            </LoadingButton>
          )}
        </ButtonsContainer>
      </div>
    </Modal>
  );
};

const PaymentModal: FC<PaymentModalProps> = ({
  open, onClose, mode, tariff, subscription, selectedTariff, selectedPattern, members, defaultMethod
}) => {
  const { t } = useTranslation();

  const params: { tariffId: string, patternId: string, addUsersCount?: number } = {
    tariffId: selectedTariff.id,
    patternId: selectedPattern.id
  };

  if (selectedTariff.canAddUsers) params.addUsersCount = members;

  const isTariffEqual = tariff
    && selectedTariff.id === tariff.tariff.id
    && selectedPattern.id === tariff.patternId
    && selectedPattern.price === tariff.price
    && (!selectedTariff.canAddUsers || selectedTariff.usersLimit + members === tariff.usersCount);

  const { data: tariffPrices, isLoading: isPricesLoading } = useGetTariffPricesQuery(params, {
    skip: mode === 'await' || (isTariffEqual && mode !== 'view') || !open
  });
  const [ setTariff, { isLoading: isSetTariffLoading } ] = useSetTariffMutation();
  const [ createInvoice, { isLoading: isCreateInvoiceLoading } ] = useCreateInvoiceMutation();

  const [ payTariffId, setPayTariffId ] = useState<string|null>(null);
  const [ createdBill, setCreatedBill ] = useState<string|null>(null);
  const [ isSuccessOpen, setSuccessOpen ] = useState<boolean>(false);
  const [ isErrorOpen, setErrorOpen ] = useState<boolean>(false);

  const handleBill = (tariffId: string, values: { [key: string]: string }) => {
    const data: ICreateInvoiceData = {
      id: tariffId,
      source: 'tariffInstance',
      details: {
        ogrn: values.ogrn,
        companyname: values.companyname,
        inn: values.inn,
        kpp: values.kpp,
        address: values.address,
      }
    };

    createInvoice(data).unwrap().then(url => {
      setCreatedBill(url);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${t('subscription.bill_document_name')}.pdf`;
      document.body.appendChild(a);
      a.click();
      window?.ReactNativeWebView?.postMessage(`downloadDocument ${url}`);

      setSuccessOpen(true);
      // window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  const handleDownload = () => {
    if (!createdBill) return null;

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = createdBill;
    a.download = `${t('subscription.bill_document_name')}.pdf`;
    document.body.appendChild(a);
    a.click();
    // window.URL.revokeObjectURL(createdBill);
  };

  if (open) {
    // eslint-disable-next-line
    console.log('Set new tariff', !(mode === 'await' || isTariffEqual));
  }

  return (
    <>
      {(mode === 'await' || (isTariffEqual && mode !== 'view')) ? (
        <PaymentModalInner
          open={open}
          onClose={onClose}
          duration={tariff.duration}
          tariffId={tariff.id}
          tariffName={tariff.name}
          tariffDtTo={tariff.dtTo}
          tariffPrices={{
            addUsersAmount: tariff.addUsersAmount,
            basePrice: tariff.basePrice,
            canChoose: true,
            discountAmount: tariff.discountAmount,
            discountRestAmount: tariff.discountRestAmount,
            price: tariff.price,
            userPrice: tariff.userPrice,
            amountToPay: tariff.amountToPay,
            renewPrice: tariff.renewPrice
          }}
          subscription={subscription}
          onBill={(billingData) => {
            handleBill(tariff.id, billingData);
          }}
          onPay={() => {
            setPayTariffId(tariff.id);
          }}
          isPayLoading={isCreateInvoiceLoading}
          defaultMethod={defaultMethod}
        />
      ) : (
        <PaymentModalInner
          open={open}
          onClose={onClose}
          duration={selectedPattern.duration}
          tariffId={tariff.id}
          tariffDtTo={tariff.dtTo}
          tariffName={selectedTariff.name}
          tariffPrices={tariffPrices}
          subscription={subscription}
          isLoading={isPricesLoading}
          onBill={async (billingData) => {
            deleteCookie('tariff');
            const res = await setTariff(params);
            if ('data' in res) handleBill(res.data.id, billingData);
          }}
          onPay={async () => {
            deleteCookie('tariff');
            const res = await setTariff(params);
            if ('data' in res) setPayTariffId(res.data.id);
          }}
          isPayLoading={isCreateInvoiceLoading || isSetTariffLoading}
          defaultMethod={defaultMethod}
        />
      )}

      <ModalPay
        open={!!payTariffId}
        onClose={() => setPayTariffId(null)}
        onSuccess={() => setSuccessOpen(true)}
        onError={() => setErrorOpen(true)}
        tariffId={payTariffId || ''}
        renewPrice={tariff.renewPrice}
        amountToPay={tariff.amountToPay}
        tariffDtTo={tariff.dtTo}
      />

      <ModalError
        open={isErrorOpen}
        onClose={() => setErrorOpen(false)}
        onClickRetry={() => setErrorOpen(false)}
      />

      <ModalSuccess
        open={isSuccessOpen}
        onClose={() => {
          setSuccessOpen(false);
          setPayTariffId(null);
          setCreatedBill(null);
          setTimeout(() => onClose());
        }}
        isTransfer={!!createdBill}
        onClickDownload={() => handleDownload()}
      />
    </>
  );
};

export default PaymentModal;
