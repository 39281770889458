import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonsContainer, Modal } from '@forma/forma-ui-kit';

import styles from './trial-end-modal.module.css';
import { Link } from 'react-router-dom';

interface TrialEndModalProps {
  open: boolean,
  onClose: (open: false) => void
}

const TrialEndModal: FC<TrialEndModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('trial_period_end')}
    >
      <div className={styles.content}>
        <div className={styles.description}>{t('trial_end_render_description')}</div>
        <img className={styles.image} src="/images/trial-end.svg" alt="" />
        <ButtonsContainer className={styles.buttons}>
          <Button as={Link} className={styles.button} to="/select-tariff" fullWidth>
            {t('go_to_tariffs')}
          </Button>
        </ButtonsContainer>
      </div>
    </Modal>
  );
};

export default TrialEndModal;
