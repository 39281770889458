import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { ButtonsContainer, Button, LoadingButton, Modal, Input, PhoneInput, phonemasks, ThemeContext } from '@forma/forma-ui-kit';
import LangSwitch from 'components/LangSwitch';
import UserGroups from 'views/profile/UserGroups';
import OrganizationsList from 'views/profile/OrganizationsList';
import getValidationSchema from 'data/validationSchema';
import ChangePasswordModal from './ChangePasswordModal';

import { baseApi } from 'store/store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { logoutUser } from 'store/auth/authSlice';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useSetOnboardingMutation, useSetProfileDataMutation } from 'store/user/userApi';
import { selectOnboarding, selectUserData, setUserData } from 'store/user/userSlice';
import { useGetExternalFillOwnersQuery, useRemoveExternalFillOwnerMutation } from 'store/externalfill/externalfillApi';

import styles from './Profile.module.css';

interface FormValues {
  name: string,
  login: string
  phone: string
}

const fields: Array<keyof FormValues> = [ 'name', 'login', 'phone' ];

const landingUrl = process.env.REACT_APP_LANDING_URL;

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { lang } = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserData);
  const [ isShowChangePass, setShowChangePass ] = useState<boolean>(false);
  const [ selectedOwnerId, setSelectedOwnerId ] = useState<string|null>(null);

  const [ saveUserData, { isLoading: isSaveDataLoading } ] = useSetProfileDataMutation();
  const { data: owners } = useGetExternalFillOwnersQuery();
  const [ removeExternalFillOwner ] = useRemoveExternalFillOwnerMutation();

  const onboarding = useAppSelector(selectOnboarding);
  const [ setOnboarding ] = useSetOnboardingMutation();

  const { register, handleSubmit, control, setValue, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const result = await saveUserData(data);

    if ('data' in result && result.data) {
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  useEffect(() => {
    if (!user) return;
    fields.forEach(key => {
      if (key === 'phone') {
        // todo: change to universal format phone
        const x = (user.phone && user.phone.length >= 11) && user.phone.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
        setValue(key, x ? `+${x[1]}(${x[2]})-${x[3]}-${x[4]}-${x[5]}` : '' );
      } else {
        setValue(key, user[key]);
      }
    });
    // eslint-disable-next-line
  }, [user]);

  const handleClickLogout = () => {
    dispatch(logoutUser());
    dispatch(setUserData(null));
    dispatch(baseApi.util.resetApiState());

    navigate('/login');
  };

  return (
    <AdminLayout title={t('my_account')}>
      <PageTitle>{t('site_name') + ' – ' + t('my_account')}</PageTitle>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          {!user ? (
            [1, 2, 3].map(key => (
              <div className={classNames(styles.input, 'skeleton-loader')} key={key} />
            ))
          ) : (
            fields.map(key => (
              <Controller
                control={control}
                name={key}
                key={key}
                render={({ field: { value = '', ...rest } }) => {
                  if (key === 'phone') return (
                    <PhoneInput
                      id={key}
                      label={t(`fields.${key}`)}
                      placeholder={t(`placeholders.${key}`)}
                      viewStyle="secondary"
                      containerClass={styles.inputContainer}
                      labelClass={styles.label}
                      className={classNames(styles.input, styles.phone)}
                      error={!!errors?.[key]?.message}
                      errorMessage={errors?.[key]?.message && t('errors.' + errors?.[key]?.message)}
                      {...rest}
                      {...register}
                      value={value}
                      defaultMaskCode={(i18n.resolvedLanguage === 'en' || !i18n.resolvedLanguage) ? 'US' : i18n.resolvedLanguage.toUpperCase()}
                      masks={phonemasks}
                    />
                  );
                  else return (
                    <Input
                      id={key}
                      label={t(`fields.${key}`)}
                      placeholder={t(`placeholders.${key}`)}
                      viewStyle="secondary"
                      inputMode={key === 'login' ? 'email' : 'text'}
                      containerClass={styles.inputContainer}
                      labelClass={styles.label}
                      className={styles.input}
                      error={!!errors?.[key]?.message}
                      errorMessage={errors?.[key]?.message && t('errors.' + errors?.[key]?.message)}
                      {...rest}
                      {...register}
                      value={value}
                      readOnly={key === 'login'}
                    />
                  );
                }}
              />
            ))
          )}
        </div>
        <h2 className={styles.title}>{t('user_permissions_groups')}</h2>
        <UserGroups
          groups={user && user.usergroups}
          selected={user && user.usergroups}
        />
        <h2 className={styles.title}>{t('data_for_sending_external_fill')}</h2>
        <div className={styles.description}>
          {t('data_for_sending_external_fill_description')}<br />
          <a className={styles.link} href={`${landingUrl}/${lang}/privacy/`} target="_blank" rel="noreferrer">{t('learn_about_privacy')}</a>
        </div>
        <div className={styles.organizations}>
          <OrganizationsList
            items={owners}
            selected={selectedOwnerId}
            onSelect={setSelectedOwnerId}
            onRemove={removeExternalFillOwner}
            showInn
            showAddress
          />
        </div>

        <h2 className={styles.title}>{t('language')}</h2>
        <LangSwitch />
        <ButtonsContainer className={styles.buttons}>
          <Button viewStyle="secondary" shadow={true} disabled={true}>{t('change_email')}</Button>
          <Button viewStyle="secondary" shadow={true} onClick={() => setShowChangePass(true)}>{t('change_password')}</Button>
          <LoadingButton
            type="submit"
            // isLoading={isSaveGoupsLoading || isSaveDataLoading}
            isLoading={isSaveDataLoading}
            viewStyle="primary"
            shadow={true}
            disabled={!isValid}
          >
            {t('save')}
          </LoadingButton>
        </ButtonsContainer>
        <ButtonsContainer>
          <Button
            viewStyle="secondary"
            shadow
            onClick={() => onboarding && setOnboarding(Object.keys(onboarding).reduce((acc, key) => ({ ...acc, [key]: false }), {}))}
          >
            {t('reset_onboarding')}
          </Button>
          <Button
            viewStyle="tertiary"
            shadow
            onClick={handleClickLogout}
          >
            {t('sign_out_from_account')}
          </Button>
        </ButtonsContainer>
      </form>
      <ChangePasswordModal open={isShowChangePass} onClose={() => setShowChangePass(false)} />
      <Modal
        size="small"
        open={hash?.slice(1) === 'email-confirm-success'}
        title={t('account.approve_success_title')}
        buttons={[
          { children: t('continue'), viewStyle: 'primary', shadow: true }
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          {t('account.approve_success_subtitle')}
        </div>
      </Modal>
      <Modal
        size="small"
        open={hash?.slice(1) === 'email-confirm-error'}
        title={t('account.approve_error_title')}
        buttons={[
          { children: t('continue'), viewStyle: 'primary', shadow: true }
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          {t('account.approve_error_subtitle')}
        </div>
      </Modal>
    </AdminLayout>
  );
};

export default Profile;
