import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Modal } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FillDocument from 'views/FillDocument';
import copyToClipboard from 'helpers/copyToClipboard';

import {
  ITemplateRenderData,
  useGetTemplateByNameQuery,
  useGetTemplateSidesQuery,
  useRenderHtmlMutation,
  useRenderTemplateMutation
} from 'store/templates/templatesApi';
import { selectUserPermissions } from 'store/user/userSlice';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useCreateExternalFillMutation } from 'store/externalfill/externalfillApi';
import { IExternalFillItem } from 'interfaces/externalfill.interface';

import styles from './Filling.module.css';
import { TFormatDocument } from 'interfaces/templates.interface';
import TrialEndModal from 'views/Guide/TrialEndModal';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { TRIAL_ID } from 'data/constants';

const Filling = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [ searchParams ] = useSearchParams();
  const debug = searchParams.get('debug');
  const navigate = useNavigate();
  const userPermissions = useSelector(selectUserPermissions);
  const canChangeFolders = userPermissions?.includes('folders_templates');
  const [ isOpenEmpty, setOpenEmpty ] = useState(false);

  const { data: template, error: pageError } = useGetTemplateByNameQuery(slug);
  const { data: templateSides, isSuccess: sidesSucess } = useGetTemplateSidesQuery(template && template.id, { skip: !template });
  const { data: currentTariff } = useGetCurrentTariffQuery();

  const [ createExternalFill, { isLoading: isCreateExternalFillLoading } ] = useCreateExternalFillMutation();

  const [ isOpenTrial, setOpenTrial ] = useState<boolean>(false);

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (sidesSucess && !templateSides?.sides.length) setOpenEmpty(true);
    // eslint-disable-next-line
  }, [sidesSucess]);

  // if (template && sidesSucess && !templateSides.sides.length) navigate(`/editor/${slug}`, { replace: true });

  const [ renderTemplate, { isLoading: isDownloadLoading } ] = useRenderTemplateMutation();
  const [ renderHtml ] = useRenderHtmlMutation();

  const handleClickRender = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
  ) => {
    renderHtml({ id: template.id, name, sideValues }).then(result => {
      if ('data' in result) {
        copyToClipboard(result.data);
        dispatch(addNotification({ content: t('copied'), type: 'SUCCESS' }));
      }
    }).catch(e => {});
  };

  const handleClickDownload = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    formatDocument: TFormatDocument = 'pdf'
  ) => {
    if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
      setOpenTrial(true);
      return;
    }

    renderTemplate({ id: template.id, name, sideValues, format: formatDocument }).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${name}.${formatDocument}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      window?.ReactNativeWebView?.postMessage(`downloadDocument ${url}`);
    }).catch(e => {});
  };

  const handleExternalFill = async (
    name: string,
    sideValues: IExternalFillItem['sideValues'],
    attachmentNames: string[],
    ownerId: string
  ) => {
    if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
      setOpenTrial(true);
      return;
    }

    const res = await createExternalFill({
      entityId: ownerId, templateId: template.id, name, sideValues, attachmentNames
    });
    if (!('data' in res)) return;
    dispatch(addNotification({ content: t('external_fill_created'), type: 'SUCCESS' }));
    localStorage.setItem('externalFillSendingId', res.data.id);
    navigate('/documents');
  };

  return (
    <AdminLayout
      title={t('fill_document_by_template')}
      breadcrumbs={{ items: [ { name: t('my_templates'), to: '/templates', as: Link }, { name: t('fill_by_template') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('filling_document')}</PageTitle>
      <FillDocument
        name={template?.name}
        sides={templateSides?.sides}
        onClickDownload={handleClickDownload}
        isDownloadLoading={isDownloadLoading}
        onClickRender={debug ? handleClickRender : undefined}
        onExternalFill={handleExternalFill}
        isExternalFillLoading={isCreateExternalFillLoading}
        showNameInput
      />
      <TrialEndModal
        open={isOpenTrial}
        onClose={setOpenTrial}
      />
      <Modal
        size="small"
        open={isOpenEmpty}
        onClose={() => setOpenEmpty(false)}
        buttons={
          canChangeFolders ? [
            {
              viewStyle: 'primary',
              children: t('open_document_in_editor'),
              onClick: () => navigate(`/editor/${slug}`)
            }
          ] : [
            {
              viewStyle: 'primary',
              children: t('return_to_templates'),
              onClick: () => navigate('/templates')
            }
          ]
        }
      >
        <ReactSVG src="/images/empty-doc-animation.svg" />
        <p className={styles.emptyDescription}>
          <Trans i18nKey="empty_document_sides_description" />
        </p>
      </Modal>
    </AdminLayout>
  );
};

export default Filling;
