import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { addNotification } from 'store/notifications/notificationsSlice';
import TemplatesPackFill from 'views/templatesPacks/TemplatesPackFill';

import {
  useAddPackMutation,
  useGetPackQuery,
  useUpdatePackMutation,
} from 'store/packs/packsApi';
import { useGetTemplatesListSidesQuery } from 'store/templates/templatesApi';
import { ITemplatesPack } from 'interfaces/templates.interface';

const PackSides = () => {
  const { t } = useTranslation();
  const { state }: { state: { templateName: string, templates: { id: string }[] } } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [ templateName, setTemplateName ] = useState((state && state.templateName) ?? '');
  const [ templatesIds, setTemplatesIds ] = useState((state && state.templates.map(({ id }) => id)) ?? []);

  const { data: pack, isLoading: isPackLoading, error: pageError } = useGetPackQuery(id ?? '', { skip: !id });
  const { data: templatesSides, isFetching: isSidesLoading } =
    useGetTemplatesListSidesQuery({ ids: templatesIds }, { skip: !templatesIds.length || !!(id && !pack) }); // need for correct order

  const [ createPack, { isLoading: isCreateLoading } ] = useAddPackMutation();
  const [ savePack, { isLoading: isSaveLoading } ] = useUpdatePackMutation();

  useEffect(() => {
    if (!id && !state) navigate('/templates-packs');
    // eslint-disable-next-line
  }, [id, state]);

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (!pack) return;
    if (pack.templates && !templatesIds.length) setTemplatesIds(pack.templates.map(({ id }) => id));
    if (pack.name && !templateName.length) setTemplateName(pack.name);
    // eslint-disable-next-line
  }, [pack]);

  useEffect(() => {
    if (!pack || !templatesSides) return;
    pack.templates.forEach(({ id, sideIndices }) => {
      sideIndices.forEach((packSideNumber, sideIndex) => {
        if (!(templatesSides[id] && templatesSides[id].sides[sideIndex])) {
          dispatch(addNotification({ content: t('pack_templates_sides_different'), type: 'ERROR' }));
          return;
        }
      });
    });
    // eslint-disable-next-line
  }, [pack, templatesSides]);

  const handleSavePack = async (name: string, sides: { name: string, sideIndices: { templateId: string, sideIndex: number }[] }[]) => {
    const templates = sides.reduce((prev, { sideIndices }, index) => {
      const next: { [key: string]: { id: string, sideIndices: number[] } } = { ...prev };

      sideIndices.forEach(({ templateId, sideIndex }) => {
        if (!next[templateId]) next[templateId] = { id: templateId, sideIndices: [] };
        next[templateId].sideIndices[sideIndex] = index+1;
      });

      return next;
    }, {});

    // сторона в документе - сторона в пакете

    const data: Omit<ITemplatesPack, 'id'> = {
      name: name,
      sides: sides.map(({ name }) => ({ id: 'test', name })), //fixme
      templates: Object.values(templates)
    };

    const result = id ? await savePack({ ...data, id }) : await createPack(data);

    if ('data' in result && result.data) {
      navigate(`/templates-packs`);
      if (id) dispatch(addNotification({ content: t('templates_pack_saved'), type: 'SUCCESS' }));
      else dispatch(addNotification({ content: t('templates_pack_created'), type: 'SUCCESS' }));
    }
  };

  // eslint-disable-next-line
  const breadcrumbs = useMemo(() => ([{ name: t('templates_packs'), to: '/templates-packs', as: Link }]), []);
  const onBreadcrumbsChange = useCallback((value: string) => setTemplateName(value), []);

  return (
    <AdminLayout
      title={id ? t('edit_templates_pack') : t('new_templates_packs')}
      breadcrumbs={{
        items: breadcrumbs,
        value: templateName,
        placeholder: isPackLoading ? '' : t('set_templates_pack_name'),
        onChange: onBreadcrumbsChange
      }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('templates_packs')}</PageTitle>
      <TemplatesPackFill
        pack={pack}
        isLoading={isPackLoading}
        isSidesLoading={isSidesLoading}
        templateName={templateName}
        templatesSides={templatesSides}
        onSave={handleSavePack}
        isSaveLoading={isCreateLoading || isSaveLoading}
      />
    </AdminLayout>
  );
};

export default PackSides;