import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { IExternalFillItem } from 'interfaces/externalfill.interface';
import { useRenderTemplateMutation } from 'store/templates/templatesApi';
import { useGetExternalFillByIdQuery } from 'store/externalfill/externalfillApi';

interface ExternalFillDownloadProps {
  open: boolean,
  onClose: () => void,
  data: IExternalFillItem
}

const ExternalFillDownload: FC<ExternalFillDownloadProps> = () => {
  const { id } = useParams();
  const { data } = useGetExternalFillByIdQuery(id ?? '', { skip: !id });
  const [ renderTemplate ] = useRenderTemplateMutation();

  useEffect(() => {
    if (!data) return;

    renderTemplate({ id: data.templateId, name: data.name, sideValues: data.sideValues }).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${data.name}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      window?.ReactNativeWebView?.postMessage(`downloadDocument ${url}`);
    }).catch(e => {});

    // eslint-disable-next-line
  }, [data]);

  return null;
};

export default ExternalFillDownload;
