import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFromTo, ThemeContext } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import DocumentsList from 'views/documents/DocumentsList';

import { useGetDocumentsQuery } from 'store/documents/documentsApi';
import { useAppDispatch } from 'store/hooks';
import {
  IExternalFillData,
  useDuplicateExternalFillMutation,
  useRemoveExternalFillMutation,
  useSendExternalFillMutation,
  useUpdateExternalFillMutation
} from 'store/externalfill/externalfillApi';
import copyToClipboard from 'helpers/copyToClipboard';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useConfirmSignMutation } from 'store/templates/templatesApi';

const landingUrl = process.env.REACT_APP_LANDING_URL;

const Documents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { lang } = useContext(ThemeContext);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ sendingId, setSendingId ] = useState<string|null>(null);
  const [ params, setParams ] = useState<{ [key: string]: string }>({});
  const page = searchParams.get('page');
  const id = searchParams.get('id');

  const { data: documents, isFetching } = useGetDocumentsQuery({ ...params, ...getFromTo(page) });
  const [ duplicateExternalFill ] = useDuplicateExternalFillMutation();
  const [ updateExternalFill ] = useUpdateExternalFillMutation();
  const [ removeFilling ] = useRemoveExternalFillMutation();
  const [ sendExternalFill ] = useSendExternalFillMutation();
  const [ confirmSign ] = useConfirmSignMutation();

  useEffect(() => {
    const sendingId = localStorage.getItem('externalFillSendingId');
    if (!sendingId) return;
    setSendingId(sendingId);
    localStorage.removeItem('externalFillSendingId');
  }, []);

  const handleChangePage = (page: number) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
  };

  const handleChangeSearch = (value: string) => {
    setParams(prev => ({ ...prev, name: value }));
    handleChangePage(1);
  };

  const handleChangeFilter = (params: { fromCreatedAt?: string, toCreatedAt?: string }) => {
    setParams(prev => ({ ...prev, ...params }));
    handleChangePage(1);
  };


  const handleCopyLink = (key: string) => {
    const res = copyToClipboard(`${landingUrl}/${lang}/filling/${key}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
    window?.ReactNativeWebView?.postMessage(`openShare ${landingUrl}/${lang}/filling/${key}`);
  };

  const handleEditExternalFill = async (data: IExternalFillData) => {
    const res = await updateExternalFill(data);
    if ('data' in res) {
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  const handleRenameExternalFill = (id: string, name: string) => {
    // fixme
  };

  const handleRemoveExternalFill = (id: string) => {
    removeFilling(id);
  };

  const handleSendExternalFill = async (id: string, data: { login: string }) => {
    const res = await sendExternalFill({ id, email: data.login });
    if ('data' in res) {
      dispatch(addNotification({ content: t('email_sended_to_your_counteragent'), type: 'SUCCESS' }));
    }
  };

  const handleDuplicateExternalFill = async (id: string) => {
    const res = await duplicateExternalFill({ id });
    if ('data' in res) {
      dispatch(addNotification({ content: t('external_fill_created'), type: 'SUCCESS' }));
      localStorage.setItem('externalFillSendingId', res.data.id);
      navigate('/documents');
    }
  };

  const handleSignRender = (id: string) => {
    confirmSign(id);
  };

  const handleRenameRender = (id: string, name: string) => {
    // fixme
  };

  return (
    <AdminLayout title={t('documents')} >
      <PageTitle>{t('site_name') + ' – ' + t('documents')}</PageTitle>

      <DocumentsList
        items={documents?.items}
        filters={params}
        onChangeFilter={handleChangeFilter}
        onChangeSearch={handleChangeSearch}
        pagination={{
          isLoading: isFetching,
          onChange: handleChangePage,
          count: documents?.count ?? 0,
          page: page
        }}
        renderFill={{
          onSign: handleSignRender,
          onRename: handleRenameRender
        }}
        externalFill={{
          openedId: id,
          sendingId: sendingId,
          onCopyLink: handleCopyLink,
          onEdit: handleEditExternalFill,
          onRename: handleRenameExternalFill,
          onRemove: handleRemoveExternalFill,
          onDuplicate: handleDuplicateExternalFill,
          onSend: handleSendExternalFill,
        }}
      />
    </AdminLayout>
  );
};

export default Documents;
