import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { UseFormReset, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FillForm,
  FillVariations,
} from '@forma/forma-ui-kit';
import ConteragentsSearch from 'views/contragents/ConteragentsSearch';
import FillFromCardButton from './FillFromCardButton';
import FillSideTable from './FillSideTable';

import { useAddProductMutation, useGetProductsCategoriesQuery, useGetProductsQuery } from 'store/products/productsApi';
import { useLazyGetCounteragentByIdQuery } from 'store/counteragents/counteragentsApi';
import {
  ISideEditData,
  ITemplateSideGroup,
  ITemplateVariation,
  ITemplateTable,
  ISidesListEditData,
  ISideSendingData,
  ISidesListSendingData
} from 'interfaces/templates.interface';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useAppDispatch } from 'store/hooks';

import styles from './fill-document.module.css';

interface FillDocumentSideProps {
  id: string,
  name: string,
  tattrFolders: ITemplateSideGroup[],
  tables: ITemplateTable[],
  carticles: ITemplateVariation[],

  sideData?: ISideEditData|null,
  onChangeSidesData: Dispatch<SetStateAction<ISidesListEditData | undefined>>,
  sendingData?: ISideSendingData|null,
  onChangeSendingData: Dispatch<SetStateAction<ISidesListSendingData | null>>,

  isShowSelect?: boolean,
  onSetChanged: () => void,
}

interface FormRef {
  reset: UseFormReset<{ [key: string]: string }>
  getValues: UseFormGetValues<{ [key: string]: string }>,
  setValue: UseFormSetValue<{ [key: string]: string }>,
  revalidate: UseFormTrigger<{ [key: string]: string }>,
}

const FillDocumentSide: FC<FillDocumentSideProps> = ({
  id,
  tattrFolders,
  tables,
  carticles,
  sideData,
  onChangeSidesData,
  sendingData,
  onChangeSendingData,
  isShowSelect,
  onSetChanged
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormRef|null>(null);
  const [ isShowCounteragents, setShowCounteragents ] = useState<boolean>(false);

  const [ getCounteragent ] = useLazyGetCounteragentByIdQuery();
  const { data: categories } = useGetProductsCategoriesQuery();
  const [ addProduct, { isLoading: isAddLoading } ] = useAddProductMutation();

  const handleAddProduct = async (data: { [key: string]: string }) => {
    const res = await addProduct(data);
    if (!('data' in res)) return null;
    dispatch(addNotification({ content: t('product_service_was_added'), type: 'SUCCESS' }));
    return res.data;
  };

  const handleSelectFolders = (tattrs: string[]) => {
    onChangeSendingData(prev => {
      if (!prev) return prev;
      return ({ ...prev, [id]: { ...prev[id], tattrs } });
    });
  };

  const handleSelectTable = (tid: string, checked: boolean) => {
    onChangeSendingData(prev => {
      if (!prev) return prev;

      const tables = [ ...prev[id].tables ];
      if (checked) tables.push(tid);
      else {
        const index = tables.indexOf(tid);
        if (index !== -1) tables.splice(index, 1);
      }

      return ({ ...prev, [id]: { ...prev[id], tables } });
    });
  };

  const handleSelectVariation = (vid: string, checked: boolean) => {
    onChangeSendingData(prev => {
      if (!prev) return prev;

      const articles = [ ...prev[id].articles ];
      if (checked) {
        articles.push(vid);
        // TODO: сделать reset значения вариации и выделение переменных и таблиц внутри вариации

        onChangeSidesData(prev => {
          if (!prev) return prev;
          const articles = [ ...prev[id].articleValues ];
          const articleIndex = articles.findIndex(article => article.id === vid);
          if (articleIndex !== -1) articles[articleIndex].value = [];
          return {
            ...prev,
            [id]: { ...prev[id], articleValues: articles, tattrValues: {} }
          };
        });
      } else {
        const index = articles.indexOf(vid);
        if (index !== -1) articles.splice(index, 1);
      }

      return ({ ...prev, [id]: { ...prev[id], articles } });
    });
  };

  const handleSetVariations = (vid: string, ids: string[]) => {
    onChangeSidesData(prev => {
      if (!prev) return prev;

      const articleValues = [ ...prev[id].articleValues ];
      const index = articleValues.findIndex(item => item.id === vid);
      if (index === -1) articleValues.push({ id: vid, value: ids });
      else articleValues[index] = { id: vid, value: ids };

      return ({ ...prev, [id]: { ...prev[id], articleValues } });

    });
  };

  const handleChangeValid = (valid: boolean) => {
    onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], isValid: valid } }));
  };

  const handleSetCounteragent = async (cid: string) => {
    const { data } = await getCounteragent(cid);
    if (data) {
      setShowCounteragents(false);
      const nameArr = data && [data.attrValues[101], data.attrValues[102], data.attrValues[103]];
      onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], counteragentid: cid, counteragentname: data && (data.attrValues[304] || nameArr.join(' ')) } }));
      resetValues(data.attrValues);
      return data;
    } return null;
  };

  const handleSetUpdate = (checked: boolean) => {
    onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], updateCounteragent: checked } }));
  };

  const handleChangeForm = (tattrValues: { [key: string]: string }) => {
    onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], tattrValues } }));
    onSetChanged();
  };

  const handleErrorForm = (tattrErrors: { [key: string]: string }) => {
    onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], tattrErrors } }));
  };

  const handleSetProducts = (
    tableId: string,
    products: { [id: string]: { [key: string]: string } }, total: { [key: string]: string },
    customProductsIds: string[]
  ) => {
    onChangeSidesData(prev => {
      if (!prev) return prev;
      const tableValues = [ ...prev[id].tableValues ];
      const index = tableValues?.findIndex(table => table.tableId === tableId);
      if (!tableValues || index === -1) tableValues.push({ tableId, products, total, customProductsIds });
      else tableValues[index] = { tableId, products, total, customProductsIds };
      return ({ ...prev, [id]: { ...prev[id], tableValues } });
    });
    onSetChanged();
  };

  const resetValues = (data: { [key: string]: string }) => {
    const faltTattrs = tattrFolders?.flatMap(({ tattrs }) => tattrs);

    if (!faltTattrs || !formRef.current) return;

    const values = formRef.current.getValues();
    const newValues: { [key: string]: string } = {};

    Object.keys(values).forEach(key => {
      if (data[key] && faltTattrs.find(({ id }) => id === key)) newValues[key] = data[key];
      else newValues[key] = '';
    });

    formRef.current?.reset(newValues);
    setTimeout(() => formRef.current?.revalidate(), 1000);
  };

  return (
    <div>
      {!!(sideData && carticles?.length) && (
        <div className={styles.variations}>
          {sideData && carticles?.map((item) => (
            <FillVariations
              {...item}
              value={sideData.articleValues?.find(({ id }) => id === item.id)?.value ?? []}
              onChange={(ids) => handleSetVariations(item.id, ids)}
              selected={sendingData ? sendingData.articles.includes(item.id) : false}
              onSelect={checked => handleSelectVariation(item.id, checked)}
              showSelect={isShowSelect}
              key={item.id}
            />
          ))}
        </div>
      )}
      <div className={styles.fillingFields}>
        {!!tattrFolders.length && (
          <FillFromCardButton
            selectedId={sideData?.counteragentid}
            onClick={() => setShowCounteragents(!isShowCounteragents)}
            onCheck={checked => handleSetUpdate(checked)}
            selectedName={sideData?.counteragentname}
          />
        )}
        {isShowCounteragents && (
          <div className={styles.counteragents}>
            <ConteragentsSearch
              // queryHook={useGetCounteragentsQuery}
              onClickItem={(counteragent) => handleSetCounteragent(counteragent.id)}
            />
          </div>
        )}
        <div style={{ display: isShowCounteragents ? 'none' : 'block' }}>
          <FillForm
            values={sideData ? sideData.tattrValues : {}}
            folders={tattrFolders}
            onChange={values => handleChangeForm(values)}
            onErrors={errors => handleErrorForm(errors)}
            onChangeValid={valid => handleChangeValid(valid)}
            showSelect={isShowSelect}
            selected={sendingData?.tattrs}
            onChangeSelected={tattrs => handleSelectFolders(tattrs)}
            showGroupName
            showCount
            ref={formRef}
          />
        </div>
        {!!tables?.length && tables.map((table) => {
          const tableValues = sideData?.tableValues.find(({ tableId }) => tableId === table.id);
          return (
            <FillSideTable
              data={table}
              queryHook={useGetProductsQuery}
              categories={categories}
              products={tableValues?.products ?? {}}
              productsTotal={tableValues?.total ?? {}}
              customProductsIds={tableValues?.customProductsIds ?? []}
              onChangeTable={(products, total, customProductsIds) => {
                handleSetProducts(table.id, products, total, customProductsIds);
              }}
              onAddProduct={handleAddProduct}
              isAddLoading={isAddLoading}
              selected={sendingData ? sendingData.tables.includes(table.id) : false}
              onSelect={checked => handleSelectTable(table.id, checked)}
              showSelect={isShowSelect}
              key={table.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FillDocumentSide;
