import { useDispatch } from 'react-redux';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Modal, ThemeContext } from '@forma/forma-ui-kit';
import copyToClipboard from 'helpers/copyToClipboard';
import getValidationSchema from 'data/validationSchema';

import { addNotification } from 'store/notifications/notificationsSlice';

import styles from './send-external-fill-modal.module.css';

interface SendExternalFillModalProps {
  open: boolean,
  onClose?: () => void,
  onSubmit: (data: { login: string }) => void,
  urlKey: string
}

interface FormValues {
  login: string,
}

const fields = [ 'login' ];

const landingUrl = process.env.REACT_APP_LANDING_URL;

const SendExternalFillModal: FC<SendExternalFillModalProps> = ({ open, onClose, onSubmit, urlKey }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);

  const { register, handleSubmit, reset, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema(fields))
  });

  const handleFormSubmit: SubmitHandler<FormValues> = (data) => {
    onSubmit(data);
    reset({ login: '' });
    if (onClose) onClose();
  };

  const handleClose = () => {
    reset({ login: '' });
    if (onClose) onClose();
  };

  const handleCopyLink = () => {
    const res = copyToClipboard(`${landingUrl}/${lang}/filling/${urlKey}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
    window?.ReactNativeWebView?.postMessage(`openShare ${landingUrl}/${lang}/filling/${urlKey}`);
  };

  return (
    <Modal
      size="large"
      open={open}
      onClose={handleClose}
      title={t('send_to_filling_your_counteragent')}
      buttons={[
        {
          className: styles.modalButton,
          viewStyle: 'tertiary',
          children: t('copy_link'),
          onClick: handleCopyLink
        }
      ]}
    >
      <form id="send_fill_to_form" className={styles.form} onSubmit={handleSubmit(handleFormSubmit)} autoComplete="false">
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="login"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="login"
                viewStyle="secondary"
                placeholder={t('email')}
                inputMode="email"
                error={!!errors?.login?.message}
                errorMessage={errors?.login?.message && t('errors.' + errors.login.message)}
                className={styles.input}
                {...rest}
                {...register}
                value={value}
              />
            )}
          />
          <Button
            type="submit"
            disabled={!isValid}
            className={styles.button}
          >
            {t('send')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SendExternalFillModal;
