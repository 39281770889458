import { IDocumentExternal, IDocumentRender } from 'interfaces/documents.interface';
import { baseApi } from 'store/store';

export const documentsApi = baseApi
  .injectEndpoints({
    endpoints: builder => ({
      getDocuments: builder.query<{ count: number, items: (IDocumentRender|IDocumentExternal)[] }, {
        from: number, to: number, name?: string, fromCreatedAt?: string, toCreatedAt?: string
      }>({
          query: (data) => ({
            url: '/documents',
            method: 'GET',
            params: data
          }),
          providesTags: ['Documents']
        }),
    })
  });

export const {
  useGetDocumentsQuery,
} = documentsApi;