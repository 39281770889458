import { ComponentProps, Fragment, lazy, Suspense, useState } from 'react';
import {
  FillProducts,
  calculateTotalValues,
  mapTotalForCalc,
  calculateProductValues,
  nanoid,
} from '@forma/forma-ui-kit';

const ProductsImportModal = lazy(() => import('pages/Products/ProductsImportModal'));

const FillSideTable = (props: ComponentProps<typeof FillProducts>) => {
  const [ isImportOpen, setImportOpen ] = useState<boolean>(false);

  return (
    <Fragment>
      <FillProducts
        {...props}
        onClickImport={() => setImportOpen(true)}
      />
      <Suspense>
        <ProductsImportModal
          open={isImportOpen}
          onClose={setImportOpen}
          columns={props.data.columns}
          onAddProducts={async (items) => {
            const customProductsIds = props.customProductsIds ? props.customProductsIds : [];
            const next = (props.products) ? { ...props.products } : {};

            const addedIds: string[] = [];
            items.forEach(item => {
              const id = nanoid(12);
              const cols = props.data.columns.reduce((acc: { [key: string]: string }, current ) => ({ ...acc, [current.id]: item[current.id] ?? '' }), {});

              const productsIds = Object.keys(next);
              const lastProductNumber = next[productsIds[productsIds.length-1]]?.number;
              next[id] = calculateProductValues({ ...cols, count: '1' }, lastProductNumber ? Number(lastProductNumber)+1 : 1, 'ru-RU');

              addedIds.push(id);
            });

            const totalValues = calculateTotalValues(Object.values(next), mapTotalForCalc(props.data.total, props.data.showTotalRow), 'ru-RU');

            props.onChangeTable(next, totalValues, [ ...customProductsIds, ...addedIds ]);

            return true;
          }}
        />
      </Suspense>
    </Fragment>
  );
};

export default FillSideTable;
