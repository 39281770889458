import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  ButtonsContainer,
  Button,
  LoadingButton,
  Tabs,
  DocumentName,
  FillingItemSkelet,
  calculateTotalValues,
  ThemeContext,
  mapTotalForCalc,
  mapSidesToFilling,
} from '@forma/forma-ui-kit';
import { analytics } from 'helpers/analytics';
import DocumentsRequest from './DocumentsRequest';
import SelectOwnerModal from './SelectOwnerModal';
import FillDocumentSide from './FillDocumentSide';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectOnboarding } from 'store/user/userSlice';
import { setOnboardingModal } from 'store/common/commonSlice';
import { ITemplateRenderData } from 'store/templates/templatesApi';
import { ISidesListEditData, ISidesListSendingData, ITemplateSide, TFormatDocument } from 'interfaces/templates.interface';
import { IExternalFillItem } from 'interfaces/externalfill.interface';

import styles from './fill-document.module.css';

interface FillDocumentProps {
  className?: string,
  name?: string,
  sides?: ITemplateSide[],
  onClickDownload: (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    formatDocument: TFormatDocument,
    isChanged: boolean
  ) => void,
  onClickRender?: (
    name: string,
    sideValues: ITemplateRenderData['sideValues']
  ) => void,
  onExternalFill?: (
    name: string,
    sideValues: IExternalFillItem['sideValues'],
    attachmentNames: string[],
    ownerId: string
  ) => void,
  isExternalFillLoading?: boolean,
  isDownloadLoading?: boolean,
  valueSides?: ISidesListEditData,
  showNameInput?: boolean,
  showHead?: boolean,
  showFillMessage?: boolean,
  setIsFillingValid?: (statusValid : boolean) => void;
  setSidesDataForInnerRenderButton?: (data: ITemplateRenderData['sideValues']) => void,
  setNameFileForInnerRender? : (name: string) => void,
  showButtons?: boolean,
  showWordButton?: boolean,
  onSetChanged?: () => void
}

const FillDocument: FC<FillDocumentProps> = ({
  className, name: defaultName = '', sides, onClickDownload, isDownloadLoading, onClickRender, onExternalFill, isExternalFillLoading,
  valueSides, showNameInput = false, showHead = true, showFillMessage = true, showButtons = true, showWordButton = true,
  setIsFillingValid, setSidesDataForInnerRenderButton, setNameFileForInnerRender, onSetChanged
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onboarding = useAppSelector(selectOnboarding);
  const [ currentSideId, setCurrentSideId ] = useState('');
  const [ sidesData, setSidesData ] = useState<ISidesListEditData|undefined>(valueSides);
  const [ isChanged, setIsChanged ] = useState<boolean>(false);
  const [ name, setName ] = useState<string>(defaultName);
  const [ isShowSelect, setIsShowSelect ] = useState<boolean>(false);
  const [ sendingData, setSendingData ] = useState<ISidesListSendingData|null>(null);
  const [ requestDocuments, setRequestDocuments ] = useState<string[]>([]);
  const [ isOwnersOpen, setOwnersOpen ] = useState<boolean>(false);

  const { currencies, lang } = useContext(ThemeContext);
  const currency = currencies[lang];

  const currentSideIndex = sides?.findIndex(({ id }) => id === currentSideId) ?? -1;

  useEffect(() => {
    if (onboarding && !onboarding.fill_template_modal) dispatch(setOnboardingModal('fill_template_modal'));
    // eslint-disable-next-line
  }, [onboarding]);

  useEffect(() => {
    if (defaultName) setName(defaultName);
  }, [defaultName]);

  useEffect(() => {
    if (valueSides)
      setSidesData(valueSides);
    else if (sides)
      setSidesData(
        sides.reduce((acc, { id, tables, tattrFolders, carticles }) => ({
          ...acc,
          [id]: {
            id,
            tattrErrors: {},
            tattrValues: {},
            tableValues: !!tables?.length ? (
              tables.map(({ id, total, showTotalRow }) => ({
                tableId: id,
                products: {},
                total: calculateTotalValues([], mapTotalForCalc(total, showTotalRow), currency.code),
                customProductsIds: []
              }))
            ) : null,
            articleValues: !!carticles?.length ? carticles.map(({ id, variants }) => (
              { id, value: variants.filter(({ default: byDefault }) => byDefault).map(({ id }) => id) }
            )) : null,
            isValid: !tattrFolders.length
          },
        }), {})
      );

    if (sides) setCurrentSideId(sides[0].id);
  }, [sides, valueSides, currency]);

  const handleChangeName = (name: string) => {
    setName(name);
    if (setNameFileForInnerRender) {
      setNameFileForInnerRender(name);
    }
  };

  const handleClickDownload = (formatDocument : TFormatDocument = 'pdf') => {
    analytics.dowloadDocument();
    if (!sidesData) return;
    onClickDownload(
      name,
      Object.values(sidesData).map(({ tableValues, tattrErrors, tattrValues, isValid, ...rest }) => ({
        tattrValues: Object.keys(tattrValues).reduce((acc: { [key: string]: string }, key) => (
          tattrValues[key] ? { ...acc, [key]: tattrValues[key] } : acc
        ), {}),
        tableValues: tableValues?.map(table => ({ ...table, products: Object.values(table.products) })),
        ...rest
      })),
      formatDocument,
      isChanged
    );
  };

  useEffect(() => {
    if (setSidesDataForInnerRenderButton === undefined || !sidesData) return;
    setSidesDataForInnerRenderButton(Object.values(sidesData).map(({ tableValues, tattrErrors, isValid, ...rest }) => ({
      tableValues: tableValues?.map(table => ({ ...table, products: Object.values(table.products) })),
      ...rest
    })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidesData]);

  const handleClickRender = () => {
    if (!sidesData || !onClickRender) return;
    onClickRender(
      name,
      Object.values(sidesData).map(({ tableValues, tattrErrors, tattrValues, isValid, ...rest }) => ({
        tattrValues: Object.keys(tattrValues).reduce((acc: { [key: string]: string }, key) => (
          tattrValues[key] ? { ...acc, [key]: tattrValues[key] } : acc
        ), {}),
        tableValues: tableValues?.map(table => ({ ...table, products: Object.values(table.products) })),
        ...rest
      }))
    );
  };

  const handleShowSelect = () => {
    if (onboarding && !onboarding.select_external_filling_modal) dispatch(setOnboardingModal('select_external_filling_modal'));

    setIsShowSelect(true);
    if (!sides) return;

    setSendingData(sides.reduce((acc, side) => {
      if (side.id !== currentSideId)
        return ({ ...acc, [side.id]: { tattrs: [], tables: [], articles: [] } });

      const preSelectedTattrs = side?.tattrFolders.reduce((acc: string[], { tattrs }) => ([
        ...acc,
        ...tattrs.reduce((acc: string[], { id }) => ((sidesData && sidesData[side.id].tattrValues[id]) ? acc : [ ...acc, id ]), [])
      ]), []);

      const preSelectedTables = side?.tables.reduce((acc: string[], { id }) => {
        if (!sidesData) return acc;
        const table = sidesData[side.id].tableValues.find(table => id === table.tableId);
        if (!table || Object.keys(table.products).length) return acc;
        return [ ...acc, table.tableId ];
      }, []);

      const preSelectedArticles = side?.carticles.reduce((acc: string[], { id }) => {
        if (!sidesData) return acc;
        const carticle = sidesData[side.id].articleValues.find(article => id === article.id);
        if (!carticle || carticle.value.length) return acc;
        return [ ...acc, carticle.id ];
      }, []);

      return ({ ...acc, [side.id]: { tattrs: preSelectedTattrs, tables: preSelectedTables, articles: preSelectedArticles } });
    }, {}));
  };

  const handleHideSelect = () => {
    setIsShowSelect(false);
  };

  const handleSubmitOwner = (ownerId: string) => {
    if (!sidesData || !onExternalFill || !sendingData) return;

    setOwnersOpen(false);

    onExternalFill(
      name,
      Object.values(sidesData).map(({ id, tableValues, articleValues, tattrErrors, tattrValues, isValid, ...rest }) => {
        const sideData = sidesData[id];
        return ({
          id: id,
          // tattrValues: tattrValues,
          tattrValues: Object.keys(sideData.tattrValues)
            .reduce((acc, tattrId) => (
              sendingData[id].tattrs.includes(tattrId) ? acc : { ...acc, [tattrId]: sideData.tattrValues[tattrId] }
            ), {}),
          tableValues: sideData.tableValues?.filter(table => !sendingData[id].tables?.includes(table.tableId))
            .map(table => ({ ...table, products: Object.values(table.products) })),
          articleValues: sideData.articleValues?.filter(article => !sendingData[id].articles?.includes(article.id)),
          ...rest
        });
      }),
      requestDocuments,
      ownerId
    );
  };

  const selectedVariants = sidesData && Object.values(sidesData)?.reduce((acc: string[], { articleValues }) => {
    if (!articleValues) return acc;
    return [ ...acc, ...articleValues.reduce((acc: string[], { value }) => ([ ...acc, ...value ]), []) ];
  }, []);

  const sidesToFilling = sides && mapSidesToFilling(sides, selectedVariants);
  const sidesFillingCount = sidesToFilling?.map(({ id, tattrFolders, carticles, tables }) => {
    const sideValues = sidesData && sidesData[id];
    const sendingTattrs = sendingData?.[id].tattrs;
    const sendingArticles = sendingData?.[id].articles;
    const sendingTables = sendingData?.[id].tables;

    let tattrsCount = 0;
    let filledCount = 0;

    tattrFolders.forEach(({ tattrs }) => {
      tattrs.forEach(({ id: tattrId }) => {
        tattrsCount += 1;

        if (sideValues && (
          (!!sideValues.tattrValues[tattrId] && !sideValues.tattrErrors[tattrId])
          || (isShowSelect && sendingTattrs?.includes(tattrId))
        )) filledCount += 1;
      });
    });

    tables?.forEach(({ id }) => {
      tattrsCount += 1;
      const tableProducts = sideValues && sideValues.tableValues.find(table => id === table.tableId)?.products;
      if (
        (tableProducts && Object.keys(tableProducts).length)
        || (isShowSelect && sendingTables?.includes(id))
      ) filledCount += 1;
    });

    carticles?.forEach(({ id }) => {
      tattrsCount += 1;
      if (sideValues && (
        sideValues.articleValues.find(article => id === article.id)?.value?.length
        || (isShowSelect && sendingArticles?.includes(id))
      )) filledCount += 1;
    });

    return { count: filledCount, total: tattrsCount };
  });

  const isFillingValid = !!(name.length >= 3 && sidesData && sidesToFilling && Object.keys(sidesData).length === sides.length) &&
    Object.values(sidesData).every(({ id, isValid, articleValues, tableValues }) => {
      const side = sidesToFilling.find(side => String(side.id) === String(id));
      if (!side) return false;

      if (side.tattrFolders.length && !isValid) return false;

      if (side.carticles?.length) {
        if (articleValues.length < side.carticles.length) return false;
        if (!articleValues.every(({ value }) => !!value.length)) return false;
      }

      if (side.tables?.length) {
        if (!side.tables.every(({ id }) => {
          const tableValue = tableValues.find(({ tableId }) => tableId === id);
          return tableValue && Object.keys(tableValue.products).length;
        })) return false;
      }

      return true;
    });

  useEffect(() => {
    if (setIsFillingValid) setIsFillingValid(isFillingValid);
  }, [setIsFillingValid, isFillingValid]);

  const emptyFieldsCount = sidesFillingCount?.reduce((acc, { count, total }) => acc+total-count, 0);

  return (
    <div className={classNames(styles.root, className)}>
      {showHead && (
        <div className={styles.head}>
          <div>
            <DocumentName
              name={name}
              showNameInput={showNameInput}
              onSubmit={handleChangeName}
            />
            {isShowSelect && (
              <Button
                viewStyle="textPrimary"
                className={styles.headButton}
                onClick={() => dispatch(setOnboardingModal('send_external_filling_modal'))}
              >
                {t('guide.what_is_external_fill')}
              </Button>
            )}
          </div>
          {isShowSelect ? (
            <div className={styles.sendingTitle}>
              {t('sending_to_filling_side', { side: sides?.find(({ id }) => currentSideId === id)?.name ?? '' })}
            </div>
          ) : (
            <Button
              viewStyle="textPrimary"
              className={styles.headButton}
              onClick={() => dispatch(setOnboardingModal('filling_modal'))}
            >
              {t('guide.howto_create_document')}
            </Button>
          )}
        </div>
      )}
      <Tabs
        contentClassName="fill-document-content"
        dontRemoveContent
        current={currentSideId}
        onChange={setCurrentSideId}
        items={sidesToFilling?.length ? (
          sidesToFilling.map(({ id, name, tattrFolders, tables, carticles }, index) => {
            return {
              id,
              name,
              className: styles.tabButton,
              testId: `filling_tab_${index}`,
              bage: {
                content: sidesFillingCount && (
                  <span className={classNames(
                    styles.bage,
                    sidesFillingCount[index].total > sidesFillingCount[index].count ? styles.error : styles.success
                  )}>
                    {sidesFillingCount[index].count}/{sidesFillingCount[index].total}
                  </span>
                )
              },
              children: (
                <FillDocumentSide
                  id={id}
                  name={name}
                  tattrFolders={tattrFolders}
                  tables={tables}
                  carticles={carticles}
                  sideData={sidesData && sidesData[id]}
                  onChangeSidesData={setSidesData}
                  sendingData={sendingData && sendingData[id]}
                  onChangeSendingData={setSendingData}
                  isShowSelect={isShowSelect}
                  onSetChanged={() => {
                    setIsChanged(true);
                    if (onSetChanged) onSetChanged();
                  }}
                  key={id}
                />
              )
            };
          })
        ) : (
          [
            {
              id: '0',
              name: '',
              children: (
                <div className={styles.items}>
                  {[...Array(10)].map((key, index) => (
                    <FillingItemSkelet key={index} />
                  ))}
                </div>
              )
            }
          ]
        )}
      />
      {isShowSelect && (
        <div id="add_sending_documents" className={styles.documents}> {/* class for onboarding */}
          <DocumentsRequest
            items={requestDocuments}
            onChange={setRequestDocuments}
          />
        </div>
      )}
      {!!(sides && emptyFieldsCount && showButtons) && (
        <div className={styles.notice}>
          <div className={styles.noticeButtons}>
            {(sides.length > 1 && currentSideIndex > 0) && (
              <Button
                className={styles.noticeButton}
                viewStyle="secondary"
                onClick={() => setCurrentSideId(sides[currentSideIndex-1].id)}
                icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
                shadow
              >
                {sides[currentSideIndex-1].name}
                {sidesFillingCount && (
                  <span className={classNames(
                    styles.noticeBage,
                    sidesFillingCount[currentSideIndex-1].total > sidesFillingCount[currentSideIndex-1].count
                      ? styles.error
                      : styles.success
                  )}>
                    {`${sidesFillingCount[currentSideIndex-1].count}/${sidesFillingCount[currentSideIndex-1].total}`}
                  </span>
                )}
              </Button>
            )}
          </div>
          <div className={styles.noticeContent}>
            <div className={styles.emptyFields}>
              {isShowSelect
                ? `${t('count_fields', { count: emptyFieldsCount })} ${t('not_selected_not_filled')}`
                : `${t('not_filled')} ${t('count_fields', { count: emptyFieldsCount })}`
              }
            </div>
            {showFillMessage && <div>{t('fill_its_or_select_for_external_fill')}</div>}
          </div>
          <div className={styles.noticeButtons}>
            {(sides.length > 1 && currentSideIndex < sides.length-1) && (
              <Button
                className={styles.noticeButton}
                viewStyle="secondary"
                onClick={() => setCurrentSideId(sides[currentSideIndex+1].id)}
                icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
                reversed
                shadow
              >
                {sides[currentSideIndex+1].name}
                {sidesFillingCount && (
                  <span className={classNames(
                    styles.noticeBage,
                    sidesFillingCount[currentSideIndex+1].total > sidesFillingCount[currentSideIndex+1].count
                      ? styles.error
                      : styles.success
                  )}>
                    {`${sidesFillingCount[currentSideIndex+1].count}/${sidesFillingCount[currentSideIndex+1].total}`}
                  </span>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
      {(sides && showButtons) && (
        isShowSelect ? (
          <ButtonsContainer className={classNames(styles.buttons, styles.inline, 'fill-document-buttons')}> {/* class for onboarding */}
            <Button
              className={styles.button}
              viewStyle="secondary"
              onClick={handleHideSelect}
              shadow
            >
              {t('go_back')}
            </Button>
            <Button
              id="send_filling"
              className={styles.button}
              viewStyle="primary"
              disabled={!!emptyFieldsCount}
              onClick={() => setOwnersOpen(true)}
              shadow
            >
              {t('next')}
            </Button>
            <SelectOwnerModal
              open={isOwnersOpen}
              onClose={() => setOwnersOpen(false)}
              onSubmit={handleSubmitOwner}
              isLoading={isExternalFillLoading}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer className={classNames(styles.buttons, 'fill-document-buttons')}> {/* class for onboarding */}
            <LoadingButton
              id="save_filled_button_pdf"
              type="submit"
              className={styles.button}
              viewStyle="secondary"
              onClick={() => handleClickDownload('pdf')}
              disabled={!isFillingValid}
              isLoading={isDownloadLoading}
              shadow
              reversed
              data-testid="save_filled_button_pdf"
              icon={<ReactSVG src="/icons/file_types/pdf.svg" wrapper="span" />}
            >
              {t('download_pdf')}
            </LoadingButton>
            {showWordButton && <LoadingButton
              id="save_filled_button_docx"
              type="submit"
              className={styles.button}
              viewStyle="secondary"
              onClick={() => handleClickDownload('docx')}
              disabled={!isFillingValid}
              isLoading={isDownloadLoading}
              shadow
              reversed
              data-testid="save_filled_button_docx"
              icon={<ReactSVG src="/icons/file_types/docx.svg" wrapper="span" />}
            >
              {t('download_docx')}
            </LoadingButton>}
            {onClickRender && (
              <Button
                type="submit"
                className={styles.button}
                viewStyle="primary"
                onClick={handleClickRender}
                disabled={!isFillingValid}
                shadow
              >
                {t('test_render')}
              </Button>
            )}
            {/* <Checkbox
              viewStyle={butStyles.secondary}
              shadow
            >
              {t('save_to_my_documents')}
            </Checkbox> */}
            {onExternalFill && (
              <Button
                className={styles.button}
                viewStyle="secondary"
                onClick={handleShowSelect}
                disabled={isFillingValid}
                shadow
              >
                {t('send_to_fiiling')}
              </Button>
            )}
          </ButtonsContainer>
        )
      )}
    </div>
  );
};

export default FillDocument;
